import React, {FunctionComponent} from 'react';
import styled from '@emotion/styled';
import {mq} from '../../utils/media-query';

const Heading2: FunctionComponent<JSX.IntrinsicElements['h2']> = props => {
  return <Element {...props} />;
};

const Element = styled.h2({
  fontWeight: 800,
  fontSize: 42,
  fontFamily: 'Inter',
  lineHeight: '51px',
  color: '#1C2B39',
  [mq('sm')]: {},
});

export default Heading2;
