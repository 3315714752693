// Simple function to convert to the expected format(MMM DD, YYYY).
// No need to add moment and such.
export function formatDate(date: string): string {
  const _date = new Date(date);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const yearNow = _date.getFullYear();
  const monthNow = months[_date.getMonth()];
  const dayNow = _date.getDate();

  return `${monthNow} ${dayNow}, ${yearNow}`;
}
