import React, {FunctionComponent} from 'react';
import styled from '@emotion/styled';

const Paragraph: FunctionComponent<JSX.IntrinsicElements['p']> = props => {
  return <Element {...props} />;
};

const Element = styled.p({
  fontWeight: 400,
  fontSize: 14,
  fontFamily: 'Inter',
  lineHeight: '21px',
  color: '#000000',
});

export default Paragraph;
