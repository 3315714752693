import React, {FC} from 'react';
import styled from '@emotion/styled';
import _Logo from '../../icons/ew-logo-long.svg';
import _ExternalLinkIcon from '../../icons/external-link.svg';
import {mq} from '../../utils/media-query';

const Footer: FC = () => {
  return (
    <Container>
      <Logo
        src={_Logo}
        title="Evolving Web"
        aria-label="Evolving Web Logo"
        alt="Evolving Web Logo"
      />
      <ExternalLink>
        Back to evolvingweb.ca
        <ExternalLinkIcon src={_ExternalLinkIcon} alt="External Link" />
      </ExternalLink>

      <CopyrightSection>©2021 | All right reserved</CopyrightSection>
    </Container>
  );
};

const Container = styled.footer({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '100%',
  backgroundColor: '#000000',
  height: 230,
  paddingTop: 20,
  paddingBottom: 72,
  justifyContent: 'space-between',
  [mq('md') + '']: {
    flexDirection: 'row',
    height: 99,
    padding: '0 5%',
  },
});

const ExternalLinkIcon = styled.img({
  height: 24,
  width: 24,
});

const Logo = styled.img({
  width: 163,
  height: 40,
  marginRight: 17,
});

const ExternalLink = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Muli',
  fontWeight: 800,
  color: '#FFFFFF',
  lineHeight: '20px',
  fontSize: 16,
});

const CopyrightSection = styled.div({
  color: '#FFFFFF',
  fontSize: 14,
  lineHeight: '24px',
  fontFamily: 'Muli',
  fontWeight: 400,
});

export default Footer;
