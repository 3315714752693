import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import {Genre} from '../models/movie';

// Mini state management utility, alternative to redux

// This might be a bit overkill, but it's a nice and easy way to keep the state to easily extend to new behaviors

type StateType = {
  selectedGenre: Set<number>;
  availableGenres: Genre[];
};

type DispatchType =
  | {type: 'SET_SELECTED_GENRE'; payload: Set<number>}
  | {type: 'RESET'}
  | {
      type: 'SET_AVAILABLE_GENRES';
      payload: Genre[];
    };

type ActionState = {
  dispatch: React.Dispatch<DispatchType>;
  state: StateType;
};

export const FilterContext = createContext<ActionState>(
  null as unknown as ActionState,
);

export const FilterProvider: FC = ({children}) => {
  const [state, dispatch] = useReducer(stateReducer, {
    selectedGenre: new Set<number>(),
    availableGenres: [],
  });

  return (
    <FilterContext.Provider value={{dispatch, state}}>
      {children}
    </FilterContext.Provider>
  );
};

export const stateReducer = (
  state: StateType,
  action: DispatchType,
): StateType => {
  switch (action.type) {
    case 'SET_SELECTED_GENRE':
      return {...state, selectedGenre: action.payload};
    case 'SET_AVAILABLE_GENRES':
      return {
        ...state,
        availableGenres: [...action.payload],
        selectedGenre: new Set<number>(),
      };
    case 'RESET':
      return {
        ...state,
        selectedGenre: new Set<number>(),
      };
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGenre = () => {
  const {state, dispatch} = useContext(FilterContext) as {
    state: StateType;
    dispatch: React.Dispatch<DispatchType>;
  };

  const {selectedGenre: selected, availableGenres} = state;

  const getGenre = useCallback(
    (genreId: number): Genre | undefined =>
      availableGenres.find(g => g.id === genreId),
    [availableGenres],
  );

  return {
    selected,
    availableGenres,
    getGenre,
    dispatch,
  };
};
