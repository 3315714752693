import React, {FC} from 'react';
import styled from '@emotion/styled';
import Button from '../../components/basic/Button';
import Paragraph from '../../components/typography/Paragraph';
import {useGenre} from '../../state/genre';

const FilterByGenre: FC = () => {
  const {availableGenres, selected, dispatch} = useGenre();

  const handleUpdateSelected = (id: number) => {
    const selectedGenre = new Set(selected);

    selectedGenre.has(id) ? selectedGenre.delete(id) : selectedGenre.add(id);

    dispatch({type: 'SET_SELECTED_GENRE', payload: selectedGenre});

    const activeGenres = availableGenres.filter(genre =>
      selectedGenre.has(genre.id),
    );
    const params = new URLSearchParams(window.location.search);
    params.set(
      'genre',
      activeGenres
        .map(genre => genre.name)
        .join(',')
        .toLocaleLowerCase(),
    );

    const paramString =
      activeGenres.length === 0 ? '' : `?${params.toString()}`;

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}${paramString}`,
    );
  };

  const handleReset = () => {
    if (selected.size > 0) {
      dispatch({type: 'RESET'});
      window.history.replaceState(
        {},
        '',
        // eslint-disable-next-line no-restricted-globals
        `${location.pathname}`,
      );
    }
  };

  return (
    <div>
      <Paragraph style={{fontWeight: 'bold'}}>Filter by Genre</Paragraph>
      <GenreList>
        <Button
          className={selected.size === 0 ? 'active' : ''}
          onClick={handleReset}
        >
          All genres
        </Button>
        {availableGenres.length
          ? availableGenres.map(genre => (
              <Button
                key={genre.id}
                onClick={() => handleUpdateSelected(genre.id)}
                className={selected.has(genre.id) ? 'active' : ''}
              >
                {genre.name}
              </Button>
            ))
          : Array.from({length: 10}).map((_, index) => (
              <Button key={index} className="genre-placeholder" disabled>
                &nbsp;
              </Button>
            ))}
      </GenreList>
    </div>
  );
};

const GenreList = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  overflow: 'hidden',
  rowGap: 10,
  '& > button:not(:last-child)': {
    marginRight: 20,
  },
  '& .genre-placeholder': {
    minWidth: 100,
  },
  '& button': {
    backgroundColor: 'var(--color-primary)',
  },
  '& button.active': {
    backgroundColor: 'var(--color-primary-dark)',
  },
});

export default FilterByGenre;
