import React, {FunctionComponent} from 'react';
import styled from '@emotion/styled';

const Heading4: FunctionComponent<JSX.IntrinsicElements['h3']> = props => {
  return <Element {...props} />;
};

const Element = styled.h3({
  margin: '15px 0',
  fontWeight: 800,
  fontSize: 24,
  fontFamily: 'Inter',
  lineHeight: '29px',
  color: '#1C2B39',
});

export default Heading4;
