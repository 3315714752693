import styled from '@emotion/styled';

const GenreList = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  rowGap: 10,
  marginBottom: 23,
  '& > button:not(:last-child)': {
    marginRight: 11,
  },
});

export default GenreList;
