import React, {FC} from 'react';
import styled from '@emotion/styled';
import Button from '../../components/basic/Button';
import Heading2 from '../../components/typography/Heading2';
import Paragraph from '../../components/typography/Paragraph';
import _Heart from '../../icons/heart.svg';
import {IMovie} from '../../models/movie';
import {useGenre} from '../../state/genre';
import {formatDate} from '../../utils/date-formatter';
import {mq} from '../../utils/media-query';
import GenreList from '../genre/GenreList';
import GenreButton from './GenreButton';

const FeatureMovieDetails: FC<{
  movie: IMovie;
}> = ({movie}) => {
  const {getGenre} = useGenre();

  return (
    <Container>
      <GenreList>
        {movie.genre_ids.map(id => (
          <GenreButton tabIndex={-1} key={id}>
            {getGenre(id)?.name}
          </GenreButton>
        ))}
      </GenreList>
      <Date>{formatDate(movie.release_date)}</Date>

      <Heading2>{movie.title}</Heading2>
      <Overview>{movie.overview}</Overview>

      <VoteButton aria-label={`Vote Button for ${movie.title}`}>
        Vote for this movie <img src={_Heart} alt="Heart" />
      </VoteButton>
    </Container>
  );
};

export const FeatureMovieDetailsSkeleton: FC = () => {
  return <Container />;
};

const Container = styled.article({
  width: '100%',
  backgroundColor: '#A2DBD6',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '32px 30.5px 32px 30.5px',
  [mq('md')]: {
    height: 720,
    padding: '32px 10%',
  },

  '& .genre-placeholder': {
    minWidth: 100,
  },
  '& .date-placeholder': {
    height: 16,
    minWidth: 100,
    maxWidth: 100,
    borderRadius: 5,
    backgroundColor: '#ffffff',
  },
  '& .title-placeholder': {
    height: 30,
    minWidth: 300,
    maxWidth: 300,
    borderRadius: 10,
    backgroundColor: '#ffffff',
  },
  '& .overview-placeholder': {
    backgroundColor: '#ffffff',
    height: 30,
    minWidth: '100%',
    borderRadius: 10,
  },
});

const Date = styled.div({
  fontFamily: 'Inter',
  fontWeight: 500,
  color: '#1C2B39',
  letterSpacing: 2,
});

const Overview = styled(Paragraph)({
  fontSize: 20,
  lineHeight: '30px',
  textAlign: 'left',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 8,
});

const VoteButton = styled(Button)({
  backgroundColor: '#1C2B39',
  borderRadius: 25,
  height: 50,
  width: 225,
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: '20px',
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    marginLeft: 10,
  },
  '&:hover': {
    backgroundColor: '#000000',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)',
  },
  transition: 'all 0.2s ease-in-out',
});

export default FeatureMovieDetails;
