import React, {FunctionComponent} from 'react';
import styled from '@emotion/styled';
import {mq} from '../../utils/media-query';

const Heading1: FunctionComponent<JSX.IntrinsicElements['h1']> = props => {
  return <Element {...props} />;
};

const Element = styled.h1({
  fontWeight: 800,
  fontFamily: 'Inter',
  color: '#1C2B39',
  textAlign: 'left',
  lineHeight: '44px',
  fontSize: 36,
  [mq('md')]: {
    fontSize: 60,
    lineHeight: '70px',
  },
});

export default Heading1;
