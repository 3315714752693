import React, {useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import Heading1 from '../components/typography/Heading1';
import _Separator from '../icons/separator.svg';
import {Genre, IMovie} from '../models/movie';
import FilterByGenre from '../modules/filter/FilterByGenre';
import Footer from '../modules/footer/Footer';
import Header from '../modules/header/Header';
import EmptyList from '../modules/movies/EmptyList';
import ErrorBoundary from '../modules/movies/ErrorBoundary';
import FeaturedMovie, {
  FeatureMovieSkeleton,
} from '../modules/movies/FeaturedMovie';
import PopularMovie from '../modules/movies/PopularMovie';
import {useGenre} from '../state/genre';
import {mq} from '../utils/media-query';

const apiKey = process.env.REACT_APP_MOVIES_API_KEY;

function Home(): JSX.Element {
  useFetchGenres();
  const {movies, loading, error} = useFetchMovies();

  if (error) {
    // We can fine tune where we want this to show
    // For now I'll just show it on the whole page
    throw new Error(error);
  }

  const [featured, ...restOfMovies] = movies;
  const [second, third, fourth] = restOfMovies;

  return (
    <>
      <HeaderWrapper>
        <Header />
        <Heading1>What’s your favourite Movie?</Heading1>

        <Separator />
        <FilterByGenre />
      </HeaderWrapper>
      <Layout>
        <ErrorBoundary>
          {featured && <FeaturedMovie movie={featured} />}
          {loading && <FeatureMovieSkeleton />}
          {restOfMovies.length > 0 && (
            <PopularMovies>
              {[second, third, fourth]
                .filter(obj => !!obj)
                .map(movie => (
                  <PopularMovie key={movie.id} movie={movie} />
                ))}
            </PopularMovies>
          )}
          {movies.length === 0 && <EmptyList />}
        </ErrorBoundary>
      </Layout>
      <Footer />
    </>
  );
}

// The only benefit of this is to make the home component readable
const useFetchMovies = () => {
  const [movies, setMovies] = useState<IMovie[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const {selected} = useGenre();

  const urlCache = useRef<string>();

  // This hook will fetch the movies
  useEffect(() => {
    setLoading(true);

    let apiEndpoint: string;

    if (selected.size === 0) {
      apiEndpoint = `https://api.themoviedb.org/3/trending/movie/day?api_key=${process.env.REACT_APP_MOVIES_API_KEY}&sort_by=popularity.desc`;
    } else {
      const genresArray = Array.from(selected).join(',');

      apiEndpoint = `https://api.themoviedb.org/3/discover/movie?api_key=${process.env.REACT_APP_MOVIES_API_KEY}&language=en-US&sort_by=popularity.desc&page=1&with_genres=${genresArray}`;
    }

    if (urlCache.current !== apiEndpoint && apiEndpoint) {
      fetch(apiEndpoint)
        .then(res => res.json())
        .then(res => {
          const _genres = new Set<number>();

          res.results.forEach((movie: IMovie) => {
            movie.genre_ids.forEach((id: number) => _genres.add(id));
          });

          setMovies(res.results);
        })
        .catch(e => {
          setError(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
      urlCache.current = apiEndpoint;
    }
  }, [selected]);

  return {movies, error, loading};
};

const useFetchGenres = () => {
  const {dispatch} = useGenre();

  useEffect(() => {
    fetch(
      `https://api.themoviedb.org/3/genre/movie/list?api_key=${apiKey}&language=en-US`,
    )
      .then(response => response.json())
      .then((res: {genres: Genre[]}) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        dispatch({
          type: 'SET_AVAILABLE_GENRES',
          payload: res.genres,
        });

        // If we have a genre in the url, we will set it as selected
        if (params.genre) {
          const splittedGenre = params.genre
            .split(',')
            .map(_genre => {
              const matchingGenre = res.genres.find(
                genre =>
                  genre.name.toLocaleLowerCase() === _genre?.toLowerCase(),
              );

              return matchingGenre?.id ?? null;
            })
            .filter(genre => genre !== null) as number[];

          const matchingGenres = new Set(splittedGenre);
          dispatch({
            type: 'SET_SELECTED_GENRE',
            payload: matchingGenres,
          });
        }
      });
  }, [dispatch]);
};

// Another way to use emotion which resembles vanilla CSS/SCSS.
// The reason I chose emotion over the other css library/preprocessor.
const Layout = styled.main`
  padding: 0;
  margin-bottom: 108px;

  @media screen and (min-width: 992px) {
    padding: 0 5%;
  }

  & {
    display: flex;
    flex-direction: column;
  }
`;

const HeaderWrapper = styled.div({
  overflow: 'hidden',
  padding: '0 30px',
  marginBottom: '30px',
  [mq('md')]: {
    marginBottom: 51,
    padding: '0 80px',
  },
});

const Separator = styled.div({
  width: '100%',
  height: 5,
  backgroundImage: 'url(' + _Separator + ')',
  marginBottom: 55,
});

const PopularMovies = styled.div({
  marginTop: 39,
  display: 'grid',
  columnGap: 30,
  gridTemplateColumns: '1fr',
  padding: '0 5%',
  rowGap: 30,
  [mq('md')]: {
    gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
    padding: '0 1%',
  },
  [mq('lg')]: {
    gridTemplateColumns: 'repeat(3, minmax(300px, 1fr))',
  },
});

export default React.memo(Home);
