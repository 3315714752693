import React, {FC} from 'react';
import styled from '@emotion/styled';
import {IMovie} from '../../models/movie';
import {mq} from '../../utils/media-query';
import FeatureMovieDetails, {
  FeatureMovieDetailsSkeleton,
} from './FeatureMovieDetails';
import MovieBackdropImage from './MovieBackdropImage';

const FeaturedMovie: FC<{movie: IMovie}> = ({movie}) => {
  return (
    <Container>
      <Image movie={movie} />
      <FeatureMovieDetails movie={movie} />
    </Container>
  );
};

export const FeatureMovieSkeleton: FC = () => {
  return (
    <Container className="container-placeholder">
      <div className="img-placeholder" />
      <FeatureMovieDetailsSkeleton />
    </Container>
  );
};

const Image = styled(MovieBackdropImage)({
  minHeight: 325,
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const Container = styled.section({
  display: 'grid',
  gridTemplateColumns: '1fr',
  [mq('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  '& .img-placeholder': {
    minHeight: 325,
    backgroundColor: '#ababab',
    width: '100%',
  },
  '.container-placeholder': {
    minHeight: 835,
    width: '100%',
  },
});

export default React.memo(FeaturedMovie);
