import styled from '@emotion/styled';
import Button from '../../components/basic/Button';

const GenreButton = styled(Button)({
  '&:hover': {
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    color: '#121212',
  },
  cursor: 'auto',
  transition: 'all 0.2s ease-in-out',
});

export default GenreButton;
