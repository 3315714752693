import React, {FC} from 'react';
import styled from '@emotion/styled';
import Heading4 from '../../components/typography/Heading4';
import _Paragraph from '../../components/typography/Paragraph';
import _Heart from '../../icons/heart.svg';
import {IMovie} from '../../models/movie';
import {useGenre} from '../../state/genre';
import {formatDate} from '../../utils/date-formatter';
import {mq} from '../../utils/media-query';
import _GenreList from '../genre/GenreList';
import GenreButton from './GenreButton';
import MovieBackdropImage from './MovieBackdropImage';

const PopularMovie: FC<{movie: IMovie}> = ({movie}) => {
  const {getGenre} = useGenre();

  return (
    <Container>
      <GenreList>
        {movie.genre_ids.map(id => (
          <GenreButton tabIndex={-1} key={id}>
            {getGenre(id)?.name}
          </GenreButton>
        ))}
      </GenreList>
      <Image movie={movie} alt={movie.title} title={movie.title} />

      <Date>{formatDate(movie.release_date)}</Date>
      <Heading4>{movie.title}</Heading4>
      <Paragraph title={movie.overview}>{movie.overview}</Paragraph>
      <VoteButton
        role="button"
        tabIndex={0}
        aria-label={`Vote Button for ${movie.title}`}
        title={`Vote Button for ${movie.title}`}
      >
        <img src={_Heart} alt="Heart" />
      </VoteButton>
    </Container>
  );
};

const GenreList = styled(_GenreList)({
  position: 'absolute',
  top: 20,
  left: 20,
});

const Container = styled.div({
  minHeight: 644,
  position: 'relative',
});

const Paragraph = styled(_Paragraph)({
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'left',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 5,
});

const Date = styled.div({
  fontFamily: 'Inter',
  fontWeight: 500,
  color: '#1C2B39',
  letterSpacing: 2,
  fontSize: 12,
  lineHeight: '16px',
});

const Image = styled(MovieBackdropImage)({
  maxHeight: 353,
  minHeight: 353,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 24.21,
  [mq('md')]: {
    marginBottom: 27.21,
  },
});

const VoteButton = styled.div({
  height: 50,
  width: 50,
  borderRadius: '50%',
  backgroundColor: '#1C2b39',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  [mq('md') + '']: {
    position: 'absolute',
    bottom: 0,
  },
  '&:hover': {
    backgroundColor: 'orangered',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)',
  },
  transition: 'all 0.2s ease-in-out',
});

export default React.memo(PopularMovie);
