import React, {FC} from 'react';
import styled from '@emotion/styled';
import {IMovie} from '../../models/movie';

const MovieBackdropImage: FC<
  JSX.IntrinsicElements['img'] & {
    movie: IMovie;
  }
> = ({movie, ...props}) => {
  return (
    // We can add lazyloding of image, but since there are a few, there is no need for it
    <Picture>
      <source
        media="(min-width:1280px)"
        srcSet={`https://image.tmdb.org/t/p/w1280${movie.backdrop_path}`}
      />
      <source
        media="(min-width:780px)"
        srcSet={`https://image.tmdb.org/t/p/w780${movie.backdrop_path}`}
      />

      {/* Since the smaller version looks terrible, I'll still load the larger image */}
      <Image
        srcSet={`https://image.tmdb.org/t/p/w780${movie.backdrop_path}`}
        alt={movie.title}
        {...props}
      />
    </Picture>
  );
};

const Picture = styled.picture({
  height: '100%',
  width: '100%',
});

const Image = styled.img({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  backgroundColor: '#ababab',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)',
  },
  transition: 'transform 0.2s ease-in-out',
});

export default React.memo(MovieBackdropImage);
