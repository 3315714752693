import React from 'react';
import ErrorBoundary from './modules/movies/ErrorBoundary';
import Home from './pages/Home';
import {FilterProvider} from './state/genre';

function App(): JSX.Element {
  return (
    <FilterProvider>
      <ErrorBoundary>
        <Home />
      </ErrorBoundary>
    </FilterProvider>
  );
}

export default App;
