import React from 'react';
import type {FunctionComponent} from 'react';
import styled from '@emotion/styled';

const Button: FunctionComponent<JSX.IntrinsicElements['button']> = props => {
  return <Element type="button" {...props} />;
};

const Element = styled.button({
  borderRadius: 15,
  height: 30,
  backgroundColor: 'var(--color-primary)',
  border: 'none',
  fontFamily: 'Inter',
  lineHeight: '21px',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#000000',
  padding: '4px 13px 5px 13px',
  minWidth: 'fit-content',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'var(--color-primary-dark)',
  },
  transition: 'background-color 0.2s ease-in-out',
});

export default Button;
