import React, {FC} from 'react';
import styled from '@emotion/styled';
import _Logo from '../../icons/ew-logo.svg';

const Header: FC = () => {
  return (
    <Container>
      <Logo
        src={_Logo}
        title="Evolving Web"
        aria-label="Evolving Web Logo"
        alt="Evolving Web Logo"
      />
      <AppName>Movie Nights</AppName>
    </Container>
  );
};

const Container = styled.header({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 110,
});

const AppName = styled.div({
  fontWeight: 800,
  fontSize: 32,
  fontFamily: 'Muli',
  lineHeight: '40px',
  color: '#000000',
});

const Logo = styled.img({
  width: 42,
  height: 40,
  marginRight: 17,
});

export default Header;
